import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import "./header.css";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Modal, Button, Form, NavDropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import updateprofileicon from "../images/user.png";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import profile_img from "../images/profile.png";
import profile_dark_img from "../images/profile-dark.png";
import notification_img from "../images/notification-bell.png";
import notification__dark_img from "../images/notification-bell-dark.png";
import no_notification_img from "../images/no-alarm.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import socketIOClient from "socket.io-client";
const ENDPOINT = "http://127.0.0.1:4001";

export interface HeaderProps {
  createproject: (arg: string) => void;
}

export const Header = ({
  createProject,
  setcreateProject,
  socket,
  isDarkModeActive,
  setIsDarkModeActive,
}) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  const { wallet, publicKey, sendTransaction } = useWallet();
  const initialState = "Dashboard";
  const [showTitle, setshowTitle] = useState(initialState);
  const [profile, setprofile] = useState("");
  const [name, setname] = useState("");
  const [createProfile, setcreateProfile] = useState(false);
  const [response, setResponse] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notification_count, setnotification_count] = useState(0);
  const [open, setOpen] = useState(false);
  const [callGetNotification, setcallGetNotification] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  

  const get_profile = async () => {
    try {
      // const response = await fetch(process.env.REACT_APP_API_URL + 'getProfile/' + publicKey.toString(), { mode: 'cors' });
      // const data = await response.json();
      // setcallGetNotification(true)
      // setprofile(data['profileName'])
      // setname(data['profileName'])
    } catch (e) {
      console.log(e);
    }
  };

  let handleSubmit = async (e) => {
    console.log("dd");
    e.preventDefault();
    let iserror = false;
    if (name.length === 0) {
      iserror = true;
    }

    if (!iserror) {
      try {
        let res = await fetch(process.env.REACT_APP_API_URL + "createProfile", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            profileName: name,
            walletAddress: publicKey.toString(),
          }),
        });
        let resJson = await res.text();
        setcreateProfile(false);
        if (res.status === 200) {
          console.log("success");
          Swal.fire({
            title: "Your profile name has been updated successfully",
            confirmButtonText: "Close",
          });
          setprofile(resJson);
        } else {
          console.log("failure");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      Swal.fire({
        title: "Name is empty",
        text: "Please check and submit again.",
        confirmButtonText: "Close",
      });
    }
  };

  const handleClose = () => setcreateProfile(false);

  function open_create_project_modal() {
    setcreateProject(true);
  }

  function open_create_profile_modal() {
    setcreateProfile(true);
  }

  useEffect(() => {
    if (location.state && location.state.from == "my-project") {
      setshowTitle("My Projects");
    } else if (location.state && location.state.from == "project-milestone") {
      setshowTitle("Project Details");
    } else if (location.state && location.state.from == "dashboard") {
      setshowTitle("Dashboard");
    } else if (location.state && location.state.from == "transactions") {
      setshowTitle("Transactions");
    } else if (location.state && location.state.from == "chat") {
      setshowTitle("Chat");
    } else if (
      location.state &&
      location.state.from == "resolution-management"
    ) {
      setshowTitle("Resolution Management");
    } else if (location.state && location.state.from == "support-ticket") {
      setshowTitle("Support Management");
    } else if (location.state && location.state.from == "notification") {
      setshowTitle("Notifications");
    } else {
      setshowTitle("");
    }

    if (publicKey) {
      localStorage.setItem("p_key", publicKey.toString());

      //   setInterval(init, 1000);
    }
  }, [setshowTitle, publicKey, location.state]);

  useEffect(() => {
    socket.on("FromAPI", (data) => {
      setResponse(data);
    });
    socket?.emit("newUser", publicKey.toString());
  }, [publicKey]);

  useEffect(() => {
    get_profile();

    socket.on("getText", (data) => {
      let old_msg = localStorage.getItem("notification");
      let updatedNotifications = [];
      if (old_msg != undefined) {
        updatedNotifications = [...JSON.parse(old_msg), data];
      } else {
        updatedNotifications = [data];
      }
      let c = notification_count;
      setnotification_count(c + 1);

      localStorage.setItem(
        "notification",
        JSON.stringify(updatedNotifications)
      );

      setNotifications(updatedNotifications);
      toast(data.message, {
        className: "instant-notification-background",
        position: "top-right",
        // autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  }, []);

  const get_notifications = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getAllMessages/" +
          publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();

      let c = 0;
      data.forEach((element) => {
        if (element.readStatus == false) {
          c = c + 1;
        }
      });
      setnotification_count(notification_count + c);
      setcallGetNotification(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleNotification = () => {
    socket?.emit("sendText", {
      message: "Project has approved by seller",
      from: publicKey.toString(),
      to: "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi",
    });
  };

  const handleRead = () => {
    localStorage.removeItem("notification");
    setNotifications([]);
    setnotification_count(0);
    setOpen(false);
  };

  const switchModes = (mode) => {
    if (isDarkModeActive) {
      setIsDarkModeActive(false);
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      setIsDarkModeActive(true);
      document.documentElement.setAttribute("data-theme", "dark");
    }
    // if (mode === "light") {
    //   setIsDarkModeActive(false);
    //   document.documentElement.setAttribute("data-theme", "light");
    // } else if (mode === "dark") {
    //   setIsDarkModeActive(true);
    //   document.documentElement.setAttribute("data-theme", "dark");
    // }
  };

  const showMenu = () => {   
      document.documentElement.classList.add('show-mobile-menu');
  };
  

  useEffect(() => {
    if (callGetNotification == true) {
      get_notifications();
    }
  }, [callGetNotification]);

  // useEffect(() => {
  // 	socket?.emit("newUser", publicKey.toString());
  //   }, [socket, publicKey]);

  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  return (
    <>
      <div id="kt_header" className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"  onClick={() => showMenu()}
          >
            <div
              className="btn btn-icon btn-active-color-primary w-40px h-40px"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center" id="kt_header_wrapper">
            <div
              className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-2 pb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
            >
              <h1 className="text-dark fw-bolder my-1 fs-3 lh-1">
                {showTitle}
              </h1>
              {/* <ul className="breadcrumb fw-bold fs-8 my-1">
							<li className="breadcrumb-item text-muted">
								<a href="../../demo4/dist/index.html" className="text-muted">Home</a>
							</li>
							<li className="breadcrumb-item text-muted">Crafted</li>
							<li className="breadcrumb-item text-muted">Pages</li>
							<li className="breadcrumb-item text-muted">Blog</li>
						</ul> */}
            </div>
          </div>

          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div
              className="d-flex align-items-stretch"
              id="kt_header_nav"
            ></div>

            <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                {/* <a href="#" className="btn btn-primary er fs-6 px-8 py-4" data-bs-toggle="modal" data-bs-target="#kt_modal_new_contract">Create Project</a> */}
                <a
                  href="#"
                  className="btn btn-primary er fs-6 px-8 py-4 create-project-btn"
                  onClick={open_create_project_modal}
                >
                  <i className="bi bi-plus-lg"></i>
                  Create Project
                </a>
              </div>

              {/* <div className="d-flex align-items-center ms-1 ms-lg-3">
							<a href="#" className="btn btn-profile-update er fs-6 px-8 py-4" onClick={open_create_profile_modal}>Profile Update</a>

						</div>
						<div className="d-flex align-items-center ms-1 ms-lg-3">
							{wallet && <WalletDisconnectButton className="btn btn-primary er fs-6 px-8 py-4" />}

						</div> */}
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <div className="switch-container">
                  <label htmlFor="checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="checkbox"
                      onClick={() => switchModes("dark")}
                    />
                    <div className="toggle">
                      <svg
                        id="graphic"
                        viewBox="0 0 132.29 132.29"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="sung">
                          <path
                            id="sun"
                            d="m99.722 66.146a33.576 33.576 0 0 1-33.576 33.576 33.576 33.576 0 0 1-33.576-33.576 33.576 33.576 0 0 1 33.576-33.576 33.576 33.576 0 0 1 33.576 33.576"
                          />
                          <g
                            id="sunshine"
                            transform="matrix(.92602 0 0 .92602 -10.774 -58.541)"
                          >
                            <path
                              transform="matrix(0 .58145 -.59067 0 145.26 159.26)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(-.41115 .41115 -.41766 -.41766 109.64 196.03)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(-.58145 0 0 -.59067 58.451 196.84)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(-.41115 -.41115 .41766 -.41766 21.685 161.22)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(0 -.58145 .59067 0 20.875 110.03)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(.41115 -.41115 .41766 .41766 56.495 73.269)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(.58145 0 0 .59067 107.68 72.459)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                            <path
                              transform="matrix(.41115 .41115 -.41766 .41766 144.45 108.08)"
                              d="m-19.4 32.282h-45.867l22.934-39.722z"
                            />
                          </g>
                        </g>
                        <path
                          id="croissant"
                          d="m55.461 34.281a33.587 33.587 0 0 0-22.874 31.832 33.587 33.587 0 0 0 33.587 33.587 33.587 33.587 0 0 0 30.441-19.395 31.187 31.187 0 0 1-17.268 5.2174 31.187 31.187 0 0 1-31.187-31.187 31.187 31.187 0 0 1 7.3022-20.053z"
                        />
                      </svg>
                    </div>
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <NavDropdown
                  title={
                    <span className="profile-name">
                      <img
                        width={40}
                        src={isDarkModeActive ? profile_dark_img : profile_img}
                      />
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item>
                    <div>
                      {profile && profile}
                      {!profile && "Hey Anon"}
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={open_create_profile_modal}>
                    <img src={updateprofileicon} width={30} /> Update Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    {" "}
                    {wallet && (
                      <WalletDisconnectButton className="btn btn-sm er fs-6 px-8 py-4 wallet-adapter-button-inside" />
                    )}
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
						
							<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                </NavDropdown>
              </div>

              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <Link
                  to={{ pathname: "/notification" }}
                  state={{ from: "notification" }}
                  onClick={handleRead}
                >
                  <span className="profile-name">
                    <img
                      src={
                        isDarkModeActive
                          ? notification__dark_img
                          : notification_img
                      }
                    />

                    {notification_count > 0 && (
                      <span className="icon-button__badge">
                        {notification_count}
                      </span>
                    )}
                  </span>
                </Link>

                <ToastContainer />
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <Modal show={createProfile} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Profile Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
            id="kt_modal_profile_update_form"
            className="form"
            action="#"
          >
            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Name</span>
              </label>

              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Enter Profile Name"
                value={name}
                name="name"
                onChange={(e) => setname(e.target.value)}
              />
            </div>

            <div className="text-center">
              <button
                type="reset"
                className="btn btn-light me-3"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary"
                data-kt-modal-action-type="submit"
              >
                <span className="indicator-label">Update Profile</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="psst">
        <div>
          <p>
            <span>
              <b>Mainnet Beta Version 1.0</b>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Header;
