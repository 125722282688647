import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "./resolution-management.css";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Dropzone from "dropzone";
import { PublicKey } from "@solana/web3.js";
import {
  createEscrowApproveTransaction,
  createSPLEscrowApproveTransaction,
} from "../util/escrow";
import S3 from "react-aws-s3";
import reject_img from "../images/rejected.png";
import comment_img from "../images/quotes.png";
import accept_img from "../images/accept.png";

import moment from "moment";

export const ResolutionManagement = ({ socket }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const { wallet, publicKey, sendTransaction } = useWallet();
  const [totalDisputes, settotalDisputes] = useState(0);
  const [milestones, setmilestones] = useState([]);
  const [buyerDisputes, setbuyerDisputes] = useState([]);
  const [sellerDisputes, setsellerDisputes] = useState([]);
  const [createDispute, setcreateDispute] = useState(false);
  const [disputePorjectId, setdisputePorjectId] = useState("");
  const [disputeMilestoneId, setdisputeMilestoneId] = useState("");
  const [disputeDiscription, setdisputeDiscription] = useState("");
  const [totalContract, settotalContract] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [milestoneTitle, setMilestoneTitle] = useState("");
  const [disputeRaisedBy, setDisputeRaisedBy] = useState("");
  const [buyerProfileName, setBuyerProfileName] = useState("");
  const [sellerProfileName, setSellerProfileName] = useState("");
  const [disputeViewReceived, SetDisputeViewReceived] = useState(false);
  const [disputeAmount, setDisputeAmount] = useState(0);
  const [tokenTypeOnDispute, setTokenTypeOnDispute] = useState("");
  const [disputeStatus, setDisputeStatus] = useState("");
  const [showComment, SetShowComment] = useState(false);
  const [acceptDispute, SetAcceptDispute] = useState(false);
  const [rejectDispute, SetRejectDispute] = useState(false);
  const [disputeComment, SetDisputeComment] = useState("");
  const [disputeCommentsHist, setDisputeCommentsHist] = useState([]);
  const [milestoneId, setMilestoneId] = useState("");
  const [milsetoneBased, setMilsetoneBased] = useState(false);
  const [escrowVaultWord, setEscrowVaultWord] = useState("");
  const [sellarAddress, setSellarAddress] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [projectId, setProjectId] = useState("");
  const { connection } = useConnection();
  const navigate = useNavigate();
  const fileInput: any = useRef();
  const [uploadSucess, setUploadSucess] = useState(false);
  const [showDetails, setshowDetails] = useState(false);
  const [rejectOrReleaseSuccess, setRejectOrReleaseSuccess] = useState(true);
  const [rejectionSucess, setRejectionSucess] = useState(false);
  const [releasePaymentSucess, setReleasePaymentSucess] = useState(false);
  const [isAttachmentAvailable, setIsAttachmentAvailable] = useState(false);
  const [fileNameFrDownload, setFileNameFrDownload] = useState();
  const [fileNameFrReq, setFileNameFrReq] = useState();
  const location = useLocation();
  const [current_user_type, setcurrent_user_type] = useState("");
  const [buyer_sellarView, setBuyer_SellarView] = useState(false);
  const [buyer_BuyerView, setBuyer_BuyerView] = useState(false);
  const [sellar_sellarView, setSellar_SellarView] = useState(false);
  const [selar_BuyerView, setSellar_BuyerView] = useState(false);
  const [showResolveDispute, setshowResolveDispute] = useState(false);

  const makeAPICall = async () => {
    try {
      // const response = await fetch('https://api.devnet-testing.thevault-x.com/api/v1/disputeView/' + publicKey.toString(), { mode: 'cors' });
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getAllDisputes/" +
          publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      // settotalDisputes(data['buyerDisputes'].length + data['sellerDisputes'].length)
      setbuyerDisputes(data["buyerDisputes"].reverse());
      // let seller_data = data['sellerDisputes'].filter(item => item['progress'] !== 'DRAFT')
      setsellerDisputes(data["sellerDisputes"].reverse());
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (publicKey) {
    console.log();
    // makeAPICall()
  }

  const init = useCallback(async () => {
    if (publicKey) {
      let acc = await connection.getAccountInfo(publicKey);
      setAccount(acc);
    }
  }, [publicKey, connection]);

  const disputeViewOnRefresh = async () => {
    try {
      let data = {
        milestoneId: milestoneId,
        milstoneBased: milsetoneBased,
        projectId: projectId,
        walletAddress: publicKey.toString(),
      };
      // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
      let res = await fetch(process.env.REACT_APP_API_URL + "disputeView", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setshowResolveDispute(false);
        SetDisputeViewReceived(true);
        setProjectName(resJson.projectName);
        setMilestoneTitle(resJson.milestoneTitle);
        setDisputeRaisedBy(resJson.disputeRaisedBy);
        setDisputeCommentsHist(resJson.disputeComments);
        sellerOrBuyer(
          resJson.disputeRaisedBy,
          publicKey.toString(),
          resJson.buyerAddress,
          resJson.sellerAddress
        );
        if (resJson.status === "IN ARBITRATION") {
          setRejectionSucess(true);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "Completed") {
          setReleasePaymentSucess(true);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "IN DISPUTE") {
          setReleasePaymentSucess(false);
          setRejectionSucess(false);
          setRejectOrReleaseSuccess(true);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }

        if (resJson.disputeRaisedBy == "BUYER") {
          setBuyerProfileName(resJson.buyerProfileName);
        } else {
          setSellerProfileName(resJson.sellerProfileName);
        }
        setDisputeAmount(resJson.disputeAmount);
        if (resJson && resJson?.tokenType) {
          setTokenTypeOnDispute(resJson.tokenType);
        } else {
          setTokenTypeOnDispute("Sol");
        }

        setDisputeStatus(resJson.status);
        console.log("success");
      } else {
        SetDisputeViewReceived(false);
        console.log("failure");
      }
    } catch (err) {
      SetDisputeViewReceived(false);
      console.log(err);
    }
  };
  function sellerOrBuyer(createdBy, p_key, buyerAddress, sellerAddress) {
    if (buyerAddress == p_key) {
      setcurrent_user_type("buyer");
    } else if (sellerAddress == p_key) {
      setcurrent_user_type("sellar");
    }
  }

  let call_dispute_details =
    (param1, param2, param3, param4, param5) => (e) => {
      const disputeView = async () => {
        try {
          let milestoneBased = param2 === "true";
          setMilestoneId(param1);
          setMilsetoneBased(milestoneBased);
          setProjectId(param3);
          setSellarAddress(param5);
          setBuyerAddress(param4);
          localStorage.setItem("d_m_id", param1);
          localStorage.setItem("d_p_id", param3);
          let data = {
            milestoneId: param1,
            milsetoneBased: milestoneBased,
            projectId: param3,
            walletAddress: publicKey.toString(),
          };

          // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
          let res = await fetch(process.env.REACT_APP_API_URL + "disputeView", {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(data),
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setshowDetails(true);
            SetDisputeViewReceived(true);
            sellerOrBuyer(
              resJson.disputeRaisedBy,
              publicKey.toString(),
              resJson.buyerAddress,
              resJson.sellerAddress
            );
            setProjectName(resJson.projectName);
            setMilestoneTitle(resJson.milestoneTitle);
            setDisputeRaisedBy(resJson.disputeRaisedBy);
            if (resJson.disputeComments) {
              setDisputeCommentsHist(resJson.disputeComments);
            } else {
              setDisputeCommentsHist([]);
            }
            setBuyerProfileName(resJson.buyerProfileName);
            setSellerProfileName(resJson.sellerProfileName);
            if (resJson.status === "IN ARBITRATION") {
              setRejectionSucess(true);
              setReleasePaymentSucess(false);
              setRejectOrReleaseSuccess(false);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            if (resJson.status === "Completed") {
              setReleasePaymentSucess(true);
              setRejectionSucess(false);
              setRejectOrReleaseSuccess(false);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            if (resJson.status === "IN DISPUTE") {
              setReleasePaymentSucess(false);
              setRejectionSucess(false);
              setRejectOrReleaseSuccess(true);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            setDisputeAmount(resJson.disputeAmount);
            setDisputeStatus(resJson.status);
            console.log("success");
          } else {
            SetDisputeViewReceived(false);
            console.log("failure");
          }
        } catch (err) {
          SetDisputeViewReceived(false);
          console.log(err);
        }
      };
      disputeView();

      //navigate('/project-milestone/'+param);
    };
  useEffect(() => {
    setshowDetails(false);
    // Send request to your server to increment page view count
  }, [location]);

  useEffect(() => {
    setIsLoading(true);

    if (publicKey) {
      localStorage.setItem("p_key", publicKey.toString());
      makeAPICall();
      //   setInterval(init, 1000);
    }
  }, [init, publicKey]);

  let handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let iserror = false;
    if (disputePorjectId.length === 0) {
      iserror = true;
    }
    if (disputeDiscription.length === 0) {
      iserror = true;
    }
    if (!iserror) {
      try {
        // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/createDispute", {
        let res = await fetch(
          process.env.REACT_APP_API_URL + "/createDispute",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              disputeDescription: disputeDiscription,
              mileStoneId: disputeMilestoneId,
              projectId: disputePorjectId,
              walletAdress: publicKey.toString(),
            }),
          }
        );
        let resJson = await res.text();
        setIsLoading(false);
        setcreateDispute(false);
        if (res.status === 200) {
          let current_dispute = findArrayElement(
            totalContract,
            disputePorjectId
          );
          if (publicKey.toString() == current_dispute["sellerAddress"]) {
            notify_user(
              "Seller has created the dispute",
              current_dispute["buyerAddress"]
            );
          } else {
            notify_user(
              "Buyer has created the dispute",
              current_dispute["sellerAddress"]
            );
          }
          Swal.fire({
            text: "Your dispute has need submitted. Our team will get back to you shortly",
            confirmButtonText: "Okey",
          }).then(() => {
            makeAPICall();
          });
        } else {
          console.log("failure");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setIsLoading(false);
      Swal.fire({
        text: "Empty or invalid form fields. Please review all the fields.",
        confirmButtonText: "Okey",
      });
    }
  };
  const handleClose = () => {
    setcreateDispute(false);
    // navigate(0);
    navigate("/resolution-management");
  };
  function open_create_dispute_modal() {
    setcreateDispute(true);
    getAllContract();
  }
  // const [acceptDispute, SetAcceptDispute] = useState(false);
  // const [rejectDispute, SetRejectDispute] = useState(false);
  // const [addDisputeCmnt, SetAddDisputeCmnt] = useState(false);
  function comments() {
    SetShowComment(true);
    SetAcceptDispute(false);
    SetRejectDispute(false);
  }
  function reject() {
    SetShowComment(false);
    SetAcceptDispute(false);
    SetRejectDispute(true);
    SetDisputeComment("");
  }
  function accept() {
    SetShowComment(false);
    SetAcceptDispute(true);
    SetRejectDispute(false);
    SetDisputeComment("");
  }

  function submitDispute() {
    setUploadSucess(false);
    if (acceptDispute) {
      // acceptAPi()
      release_payment_confirmed();
    }
    if (rejectDispute) {
      rejectAPi();
    }
    if (showComment) {
      disputeCommentAPi();
    }
  }

  const sendAndConfirmTransaction = async (transaction) => {
    try {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "recent");

      // if (onChange) onChange();

      return signature;
    } catch (err) {
      setIsLoading(false);
      console.log("Transaction error: ", err);
    }
  };
  async function release_payment_confirmed() {
    localStorage.setItem("last_api_call", "release_payment");
    setIsLoading(true);
    try {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL +
          "getAccessKey/" +
          localStorage.getItem("d_p_id") +
          "/" +
          localStorage.getItem("d_m_id"),
        {
          mode: "cors",
        }
      );
      let resJson = await access_res.text();
      localStorage.setItem("d_rp_a_k", resJson);
      setEscrowVaultWord(resJson);
      await EscrowApprove();
    } catch (e) {
      console.log(e);
    }
  }

  /**API for accept Dispute */
  const EscrowApprove = useCallback(async () => {
    let sig: any;
    if (tokenTypeOnDispute == "Vaultx") {
      sig = await sendAndConfirmTransaction(
        await createSPLEscrowApproveTransaction(
          connection,
          publicKey,
          new PublicKey(localStorage.getItem("walletAddress")),
          new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
          localStorage.getItem("escrowVaultWord")
        )
      );
    } else if (tokenTypeOnDispute == "Forge") {
      sig = await sendAndConfirmTransaction(
        await createSPLEscrowApproveTransaction(
          connection,
          publicKey,
          new PublicKey(localStorage.getItem("walletAddress")),
          new PublicKey("FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds"),
          localStorage.getItem("escrowVaultWord")
        )
      );
    } else {
      sig = await sendAndConfirmTransaction(
        await createEscrowApproveTransaction(
          connection,
          new PublicKey(buyerAddress),
          new PublicKey(sellarAddress),
          localStorage.getItem("d_rp_a_k")
        )
      );
    }

    try {
      if (sig) {
        try {
          let data = {
            milestoneId: localStorage.getItem("d_m_id"),
            projectId: localStorage.getItem("d_p_id"),
            signature: sig,
            walletAddress: publicKey.toString(),
            attachment: fileNameFrReq,
            tokenType: tokenTypeOnDispute,
            decimalDigit:
              tokenTypeOnDispute == "Vaultx"
                ? "6"
                : tokenTypeOnDispute == "Sol"
                ? "9"
                : "9",
          };
          setIsLoading(true);
          // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
          let res = await fetch(
            process.env.REACT_APP_API_URL + "releasePayment",
            {
              method: "POST",
              mode: "cors",
              headers: new Headers({ "content-type": "application/json" }),
              body: JSON.stringify(data),
            }
          );
          let resJson = await res.json();

          if (res.status === 200) {
            setIsLoading(false);
            setRejectOrReleaseSuccess(false);
            setReleasePaymentSucess(true);
            if (publicKey.toString() == sellarAddress) {
              notify_user(
                "Seller released payment the for dispute",
                buyerAddress
              );
            } else {
              notify_user(
                "Buyer released payment the for dispute",
                sellarAddress
              );
            }
            Swal.fire({
              text: "Payment released",
              confirmButtonText: "Close",
            }).then(() => {
              disputeViewOnRefresh();
            });
            console.log("success");
          } else {
            console.log("failure");
            setRejectOrReleaseSuccess(true);
            setReleasePaymentSucess(false);
          }
        } catch (e) {
          setIsLoading(false);
        }
      }
    } catch (e) {}
  }, [connection, publicKey, sendAndConfirmTransaction]);

  /**API for reject Dispute */
  const rejectAPi = async () => {
    try {
      let data = {
        milestoneId: milestoneId,
        milsetoneBased: milsetoneBased,
        projectId: projectId,
        walletAddress: publicKey.toString(),
        attachment: fileNameFrReq,
      };
      // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
      let res = await fetch(process.env.REACT_APP_API_URL + "disputeReject", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();

      if (res.status === 200) {
        console.log("success");
        setRejectOrReleaseSuccess(false);
        setRejectionSucess(true);
        if (publicKey.toString() == sellarAddress) {
          notify_user("Seller rejected the dispute", buyerAddress);
        } else {
          notify_user("Buyer rejected the dispute", sellarAddress);
        }
        Swal.fire({
          title: "Your Dispute Reject Request is Submitted Successfully. ",
          text: "Our team will get back to you shortly",
          confirmButtonText: "Ok",
        }).then(() => {
          disputeViewOnRefresh();
        });
      } else {
        console.log("failure");
        setRejectOrReleaseSuccess(true);
        setRejectionSucess(false);
      }
    } catch (e) {}
  };
  /**API for Comments on dispute*/
  const disputeCommentAPi = async () => {
    try {
      if (disputeComment) {
        let data = {
          description: disputeComment,
          disputeComment: disputeComment,
          milestoneId: milestoneId,
          milstoneBased: milsetoneBased,
          projectId: projectId,
          walletAddress: publicKey.toString(),
          attachment: fileNameFrReq,
        };
        // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
        let res = await fetch(
          process.env.REACT_APP_API_URL + "disputeComment",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(data),
          }
        );
        let resJson = await res.json();

        if (res.status === 200) {
          console.log("success");
          SetDisputeComment("");
          (
            document.getElementById("textAreaCommnt") as HTMLInputElement
          ).value = "";

          if (publicKey.toString() == sellarAddress) {
            notify_user("Seller commented on the dispute", buyerAddress);
          } else {
            notify_user("Buyer commented on the dispute", sellarAddress);
          }
          Swal.fire({
            title: "Your Dispute comment Has Submitted Successfully. ",
            text: "Our team will get back to you shortly",
            confirmButtonText: "Ok",
          }).then(() => {
            disputeViewOnRefresh();
          });
        } else {
          console.log("failure");
        }
      } else {
        Swal.fire({
          title: "",
          text: "Please Enter Comments to Proceed!",
          confirmButtonText: "Ok",
        });
      }
    } catch (e) {}
  };
  const getAllContract = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getDisputeEligibleContracts/" +
          publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      setIsLoading(false);

      settotalContract([...data["buyerContracts"], ...data["sellerContracts"]]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    SetDisputeComment(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setUploadSucess(false);
    if (fileInput) {
      let file = fileInput!.current.files[0];
      if (file) {
        let newFileName = fileInput!.current.files[0].name.replace(/\..+$/, "");
        newFileName =
          projectId.substring(0, projectId.indexOf("-")) +
          "_" +
          milestoneId.substring(0, milestoneId.indexOf("-")) +
          "_" +
          Math.random() +
          file.name;
        setFileNameFrReq(newFileName);
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          if (data.status === 204) {
            console.log("success");
            fileInput.current.value = "";
            setUploadSucess(true);
          } else {
            console.log("fail");
            fileInput.current.value = "";
            setUploadSucess(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (disputePorjectId && disputePorjectId != "Choose the Project") {
      let selected_contract = totalContract.find((element) => {
        return element.projectId === disputePorjectId;
      });

      setmilestones(selected_contract["mileStones"]);
    }
  }, [disputePorjectId]);
  const download = async (fileName) => {
    try {
      setIsLoading(true);
      await fetch(process.env.REACT_APP_API_URL + "download/" + fileName)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "vaultx";
          a.click();
        });

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const downloadRejectAccept = async () => {
    try {
      setIsLoading(true);
      await fetch(
        process.env.REACT_APP_API_URL + "download/" + fileNameFrDownload
      )
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "vaultx";
          a.click();
        });

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let notify_user = async (msg, to_address) => {
    let res = await fetch(process.env.REACT_APP_API_URL + "saveMessage", {
      method: "POST",
      mode: "cors",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        fromAddress: publicKey.toString(),
        message: msg,
        milestoneId: localStorage.getItem("m_id"),
        projectId: localStorage.getItem("p_id"),
        readStatus: false,
        toAddress: to_address,
        messageType: "dispute",
      }),
    });
    let resJson = await res.json();
    if (res.status === 200) {
      socket?.emit("sendText", {
        message: msg,
        from: publicKey.toString(),
        to: to_address,
      });
    } else {
      console.log("failure");
    }
  };

  function findArrayElement(array, selected_projectId) {
    return array.find((element) => {
      return element.projectId == selected_projectId;
    });
  }

  const gettime = (timestamp) => {
    var stillUtc = moment.utc(timestamp, "YYYY-MM-DD HH:mm:ss a").toDate();
    var local = moment(stillUtc, "YYYY-MM-DD HH:mm:ss a")
      .local()
      .format("MMMM Do YYYY, h:mm a");

    return local;
  };

  let call_dispute_details_1 = (
    project_id,
    milestone_id,
    is_milestonebased
  ) => {
    localStorage.setItem("re_is_m_based", is_milestonebased);
    navigate("/resolution-detail/" + project_id + "/" + milestone_id);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={createDispute} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Dispute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
            id="kt_modal_profile_update_form"
            className="form"
            action="#"
          >
            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Choose The Project</span>
              </label>
              <select
                className="form-select form-select-solid"
                name="created_for"
                onChange={(e) => setdisputePorjectId(e.target.value)}
              >
                <option>Choose the Project</option>
                {totalContract.map((contract) => (
                  <option
                    key={contract.projectId + "1"}
                    value={contract.projectId}
                  >
                    {contract.projectTitle}
                  </option>
                ))}
              </select>
            </div>
            {milestones.length > 0 && (
              <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span className="required">Choose The Milestone</span>
                </label>
                <select
                  className="form-select form-select-solid"
                  name="created_for"
                  onChange={(e) => setdisputeMilestoneId(e.target.value)}
                >
                  <option>Choose the Milestone</option>
                  {milestones.map((milestone) => (
                    <option
                      key={milestone.mileStoneId + "2"}
                      value={milestone.mileStoneId}
                    >
                      {milestone.mileStoneTitle}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="required fs-5 fw-bold mb-2">
                Dispute Description
              </label>

              <textarea
                className="form-control form-control-solid"
                rows={3}
                name="description"
                placeholder="Describe your dispute"
                onChange={(e) => setdisputeDiscription(e.target.value)}
              ></textarea>
            </div>

            <div className="text-center">
              <button
                type="reset"
                className="btn me-3 cancel-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary submit-btn"
              >
                <span className="indicator-label ">Submit Dispute</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
          <div className="row g-5 g-xl-8">
            <div className="col-xl-11">
              {!showDetails && (
                <div className="card mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder fs-3 mb-1">
                        Resolution Management
                      </span>
                      {/* <span className="text-muted mt-1 fw-bold fs-7">Total of {totalDisputes} Projects</span> */}
                    </h3>
                    <div className="card-toolbar">
                      <a
                        className="btn btn-md btn-create-dispute me-3"
                        onClick={open_create_dispute_modal}
                        data-bs-toggle="tab"
                      >
                          
                        Create Dispute
                      </a>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <ul className="nav nav-pills me-6 mb-6 mb-sm-0 project-tab">
                      <li className="nav-item m-0">
                        <a
                          className="buyer-dispute-btn btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 active"
                          data-bs-toggle="tab"
                          href="#kt_project_users_card_pane"
                        >
                          Buyer Dispute
                        </a>
                      </li>
                      <li className="nav-item m-0">
                        <a
                          className="seller-dispute-btn btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary"
                          data-bs-toggle="tab"
                          href="#kt_project_users_table_pane"
                        >
                          Seller Dispute
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-8">
                      <div
                        id="kt_project_users_card_pane"
                        className="tab-pane fade active show"
                      >
                        <div className="table-responsive">
                          <table className="table align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-muted">
                                <th className="w-10px">#</th>
                                <th className="min-w-300px">Project Name</th>
                                <th className="min-w-125px">
                                  Dispute Created On
                                </th>
                                <th className="min-w-125px">
                                  Dispute Amount (SOL)
                                </th>
                                <th className="min-w-50px">Status</th>
                                <th className="min-w-200px text-end rounded-end"></th>
                              </tr>
                            </thead>
                            {buyerDisputes.length > 0 && (
                              <tbody>
                                {buyerDisputes.map((Dispute, index) => (
                                  <tr key={Dispute.projectId + "3"}>
                                    <td className="text-dark fw-bolder">
                                      {index + 1}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex justify-content-start flex-column">
                                          <a
                                            href="#"
                                            onClick={call_dispute_details(
                                              Dispute.mileStoneId,
                                              Dispute.milestoneBased.toString(),
                                              Dispute.projectId,
                                              Dispute.buyerAddress,
                                              Dispute.sellerAddress
                                            )}
                                            className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                                          >
                                            {Dispute.projectName}
                                          </a>
                                          {/* <span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span> */}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                      >
                                        {gettime(Dispute.disputeCreatedTime)}
                                      </a>
                                      {/* <span className="text-muted fw-bold text-muted d-block fs-7">Pending</span> */}
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                      >
                                        {Dispute.disputeAmount}
                                        {Dispute.tokenType
                                          ? Dispute.tokenType == "Sol"
                                            ? "◎"
                                            : Dispute.tokenType == "Vaultx"
                                            ? "USDC"
                                            : Dispute.tokenType == "Forge"
                                            ? "FORGE"
                                            : "◎"
                                          : "◎"}
                                      </a>
                                      {/* <span className="text-muted fw-bold text-muted d-block fs-7">Paid</span> */}
                                    </td>
                                    <td className="text-dark fw-bolder">
                                      {Dispute.status}
                                    </td>
                                    <td className="text-end">
                                      <a
                                        href="#"
                                        onClick={() =>
                                          call_dispute_details_1(
                                            Dispute.projectId,
                                            Dispute.mileStoneId,
                                            Dispute.milestoneBased.toString()
                                          )
                                        }
                                        className="btn btn-view-project btn-sm px-4 me-2"
                                      >
                                        <i
                                          style={{ fontSize: "1.3rem" }}
                                          className="bi bi-eye"
                                        ></i>
                                        View
                                      </a>
                                      {/* <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>

                      <div
                        id="kt_project_users_table_pane"
                        className="tab-pane fade"
                      >
                        <div className="table-responsive">
                          <table className="table align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-muted">
                                <th className="min-w-10px">#</th>
                                <th className="ps-4 min-w-300px rounded-start">
                                  Project Name
                                </th>
                                <th className="min-w-125px">
                                  Dispute Created On
                                </th>
                                <th className="min-w-125px">
                                  Dispute Amount (SOL)
                                </th>
                                <th className="min-w-50px">Status</th>
                                <th className="min-w-200px text-end rounded-end"></th>
                              </tr>
                            </thead>

                            {sellerDisputes.length > 0 && (
                              <tbody>
                                {sellerDisputes.map((Dispute, index) => (
                                  <tr key={Dispute.projectId + "4"}>
                                    <td className="text-dark fw-bolder">
                                      {index + 1}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex justify-content-start flex-column">
                                          <a
                                            href="#"
                                            onClick={call_dispute_details(
                                              Dispute.mileStoneId,
                                              Dispute.milestoneBased.toString(),
                                              Dispute.projectId,
                                              Dispute.buyerAddress,
                                              Dispute.sellerAddress
                                            )}
                                            className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                                          >
                                            {Dispute.projectName}
                                          </a>
                                          {/* <span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span> */}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                      >
                                        {gettime(Dispute.disputeCreatedTime)}
                                      </a>
                                      {/* <span className="text-muted fw-bold text-muted d-block fs-7">Pending</span> */}
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                      >
                                        {Dispute.disputeAmount}
                                        {Dispute.tokenType
                                          ? Dispute.tokenType == "Sol"
                                            ? "◎"
                                            : Dispute.tokenType == "Vaultx"
                                            ? "USDC"
                                            : Dispute.tokenType == "Forge"
                                            ? "FORGE"
                                            : "◎"
                                          : "◎"}
                                      </a>
                                      {/* <span className="text-muted fw-bold text-muted d-block fs-7">Paid</span> */}
                                    </td>
                                    <td className="text-dark fw-bolder">
                                      {Dispute.status}
                                    </td>
                                    <td className="text-end">
                                      <a
                                        href="#"
                                        onClick={() =>
                                          call_dispute_details_1(
                                            Dispute.projectId,
                                            Dispute.mileStoneId,
                                            Dispute.milestoneBased.toString()
                                          )
                                        }
                                        className="btn btn-view-project btn-sm px-4 me-2"
                                      >
                                        <i
                                          style={{ fontSize: "1.3rem" }}
                                          className="bi bi-eye"
                                        ></i>
                                        View
                                      </a>
                                      {/* <a href="#" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4">Edit</a> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showDetails && (
                <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                  <div className="card card-flush pt-3 mb-5 mb-xl-10">
                    <div className="card-header">
                      <div className="card-title">
                        {rejectOrReleaseSuccess && (
                          <a
                            className="btn btn-md btn-primary me-3"
                            onClick={() => setshowResolveDispute(true)}
                            data-bs-toggle="tab"
                          >
                            Resolve Dispute
                          </a>
                        )}
                      </div>
                      <div className="card-toolbar">
                        <a
                          className="btn btn-md btn-back-dispute me-3"
                          onClick={() => setshowDetails(false)}
                          data-bs-toggle="tab"
                        >
                          Back To Dispute List
                        </a>
                      </div>
                    </div>

                    <div className="separator separator-dashed mb-7"></div>
                    <div className="card-body pt-3">
                      <div className="mb-10">
                        <h5 className="mb-4">Dispute Details:</h5>

                        <div className="d-flex flex-wrap py-5">
                          <div className="flex-equal me-5">
                            <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                              <tbody>
                                <tr>
                                  <td className="text-gray-400 min-w-175px w-175px">
                                    Project name:
                                  </td>
                                  <td className="text-gray-800 min-w-200px">
                                    {projectName}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">
                                    Dispute Raised By :
                                  </td>
                                  <td className="text-gray-800">
                                    {disputeRaisedBy}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-gray-400">
                                    Buyer Details:
                                  </td>
                                  <td className="text-gray-800">
                                    {buyerProfileName}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">
                                    Dispute Amount:
                                  </td>
                                  <td className="text-gray-800">
                                    {disputeAmount}{" "}
                                    {tokenTypeOnDispute
                                      ? tokenTypeOnDispute == "Sol"
                                        ? "◎"
                                        : tokenTypeOnDispute == "Vaultx"
                                        ? "USDC"
                                        : tokenTypeOnDispute == "Forge"
                                        ? "FORGE"
                                        : "◎"
                                      : "◎"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="flex-equal">
                            <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                              <tbody>
                                <tr>
                                  <td className="text-gray-400">
                                    MileStone Title:
                                  </td>
                                  <td className="text-gray-800">
                                    {milestoneTitle}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">Status:</td>
                                  <td className="text-gray-800">
                                    {disputeStatus == "IN DISPUTE"
                                      ? "In Dispute"
                                      : ""}
                                    {disputeStatus == "IN ARBITRATION"
                                      ? "Dispute Resolution in progress"
                                      : ""}
                                    {disputeStatus == "Completed"
                                      ? " Completed"
                                      : ""}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-gray-400">
                                    Seller Details:
                                  </td>
                                  <td className="text-gray-800">
                                    {sellerProfileName}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {disputeCommentsHist.length > 0 && (
                        <div className="mb-0">
                          <div className="separator separator-dashed mb-7"></div>
                          <h5 className="mb-4">Previous Comments:</h5>

                          <div className="table-responsive">
                            <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                              <thead>
                                <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                  <th className="min-w-150px">Comment By</th>
                                  <th className="min-w-125px">Comment On</th>
                                  <th className="min-w-125px">Comment</th>
                                  <th className="min-w-125px">Attachment</th>
                                </tr>
                              </thead>

                              {disputeCommentsHist.length > 0 && (
                                <tbody className="fw-bold text-gray-800">
                                  {disputeCommentsHist.map((disputeComment) => (
                                    <tr>
                                      <td>
                                        <label className="w-150px">
                                          {disputeComment.commentByProfileName
                                            ? disputeComment.commentByProfileName
                                            : disputeComment.commentBy.substring(
                                                0,
                                                4
                                              )}
                                        </label>
                                      </td>
                                      <td>
                                        {gettime(disputeComment.commentedTime)}
                                      </td>
                                      <td>{disputeComment.commentText}</td>
                                      <td>
                                        {disputeComment.attachment && (
                                          <a
                                            href="#"
                                            onClick={() =>
                                              download(
                                                disputeComment.attachment
                                              )
                                            }
                                          >
                                            <div>Download</div>
                                            <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      )}

                      {!rejectOrReleaseSuccess && rejectionSucess && (
                        <div style={{ paddingTop: "15px" }} className="fv-row">
                          <div className="dispute-status-card d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                            <h3 className="fs-5 fw-bolder text-primary mb-1">
                              Dispute Rejection Successful.
                            </h3>
                            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2"></div>
                            <div className="min-w-125px pe-2">
                              <div className="text-center">
                                <a href="#" onClick={downloadRejectAccept}>
                                  <div>Download Attachment</div>
                                  <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!rejectOrReleaseSuccess && releasePaymentSucess && (
                        <div style={{ paddingTop: "15px" }} className="fv-row">
                          <div className="dispute-status-card d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                            <h3 className="fs-5 fw-bolder text-primary mb-1">
                              Dispute Acceptance Successful.
                            </h3>
                            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2"></div>
                            <div className="min-w-125px pe-2">
                              <div className="text-center">
                                <a href="#" onClick={downloadRejectAccept}>
                                  <div>Download Attachment</div>
                                  <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/*To add here */}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showResolveDispute}
          onHide={() => setshowResolveDispute(false)}
          dialogClassName="modal-r90w"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Resolve Dispute</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              id="kt_accordion_2_item_1"
              className="fs-6 collapse show ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {rejectOrReleaseSuccess && (
                <h5 className="mb-4 text-primary">Choose Your Option:</h5>
              )}
              <div className="flex-container">
                <div className="plans">
                  {rejectOrReleaseSuccess &&
                    disputeRaisedBy == "SELLER" &&
                    current_user_type != "sellar" && (
                      <label className="plan basic-plan" onClick={accept}>
                        <input type="radio" name="plan" id="basic" />
                        <div className="plan-content">
                          <img loading="lazy" src={accept_img} alt="" />
                          <div className="plan-details">
                            <span>
                              Accept Dispute and Release the Payment of{" "}
                              {disputeAmount}◎ to{" "}
                              {sellerProfileName
                                ? sellerProfileName
                                : sellarAddress.substring(0, 4)}
                            </span>
                          </div>
                        </div>
                      </label>
                    )}
                  {rejectOrReleaseSuccess && (
                    <label className="plan basic-plan" onClick={reject}>
                      <input type="radio" name="plan" id="basic" />
                      <div className="plan-content">
                        <img loading="lazy" src={reject_img} alt="" />
                        <div className="plan-details">
                          <span> Reject Dispute and Raise for Resolution</span>
                        </div>
                      </div>
                    </label>
                  )}

                  {rejectOrReleaseSuccess && (
                    <label className="plan complete-plan" onClick={comments}>
                      <input type="radio" id="complete" name="plan" />
                      <div className="plan-content">
                        <img loading="lazy" src={comment_img} alt="" />
                        <div className="plan-details">
                          <span> Add Comment (Dispute Remain Opens)</span>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>

              {showComment && rejectOrReleaseSuccess && (
                <div className="input-group input-group-solid">
                  <span className="input-group-text">Comments</span>
                  <textarea
                    id="textAreaCommnt"
                    className="form-control"
                    aria-label="With textarea"
                    onChange={handleChange}
                  >
                    {disputeComment}
                  </textarea>
                </div>
              )}
              {showComment && rejectOrReleaseSuccess && (
                <h5 className="mb-4 text-primary">Attachments:</h5>
              )}
              {showComment && rejectOrReleaseSuccess && (
                <form
                  className="form"
                  action="#"
                  method="post"
                  onSubmit={handleClick}
                >
                  {/* {   <div style={{ paddingTop: "10px", fontWeight: "900", fontSize: "large", color: "grey" }}>Attachment/s</div>}
{<form className="form" action="#" method="post" onSubmit={handleClick}> */}
                  <div className="fv-row">
                    <div className="dropzone" id="kt_dropzonejs_example_1">
                      <div className="dz-message needsclick">
                        {/* <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i> */}
                        <div className="ms-4">
                          <h3 className="fs-5 fw-bolder text-gray-900 mb-1">
                            <input
                              type="file"
                              ref={fileInput}
                              onClick={() => setUploadSucess(false)}
                              accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*"
                            />
                          </h3>
                          <span></span>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-sm me-5 dispute-upload-btn"
                            >
                              Upload
                            </button>
                          </div>
                          {uploadSucess && (
                            <div>
                              <span className="badge badge-light-success ms-2 fs-7">
                                Upload is successful!
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {!rejectOrReleaseSuccess &&
              rejectionSucess ? null : !rejectOrReleaseSuccess &&
                releasePaymentSucess ? null : (
                <div className="text-center" style={{ paddingTop: "10px" }}>
                  <a
                    href="#"
                    onClick={() => setshowResolveDispute(false)}
                    className="btn btn-dispute-cancel me-3"
                  >
                    Cancel
                  </a>
                  <a
                    href="#"
                    onClick={submitDispute}
                    className="btn btn-primary btn-dispute-submit"
                  >
                    Submit
                  </a>
                </div>
              )}
              {/* <div style={{ paddingTop: "10px" }}><a href="#" className="btn btn-secondary">cancel</a>   <a href="#" onClick={submitDispute} className="btn btn-secondary">Submit</a>
</div> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
