import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react";
import "./resolution-management.css";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Dropzone from "dropzone";
import { PublicKey } from "@solana/web3.js";
import {
    createEscrowApproveTransaction
} from "../util/escrow";
import S3 from "react-aws-s3";

export const SupportTicket: FC = () => {
    const [ticketComment, SetTicketComment] = useState("");
    const [projectIdTicket, SetProjectIdTicket] = useState("");
    const [mileStoneIdTicket, SetMileStoneIdTicket] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { wallet, publicKey, sendTransaction } = useWallet();
    const [totalContract, settotalContract] = useState([]);
    const [milestones, setmilestones] = useState([]);
    // const [disputePorjectId, setdisputePorjectId] = useState("");
    // const [disputeMilestoneId, setdisputeMilestoneId] = useState("");

    const handleChange = (event) => {
        SetTicketComment(event.target.value);
    }
    const handleProjectIdChange = (event) => {
        SetProjectIdTicket(event.target.value);
    }
    const handleMilestoneChange = (event) => {
        SetMileStoneIdTicket(event.target.value);
    }

    const getAllContract = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(process.env.REACT_APP_API_URL + 'getAllContracts/' + publicKey.toString(), { mode: 'cors' });
            const data = await response.json();
            setIsLoading(false);
            console.log(data['buyerContracts'] + data['sellerContracts'])
            settotalContract([...data['buyerContracts'], ...data['sellerContracts']])
            console.log(totalContract)
        }
        catch (e) {
            console.log(e)
        }
    }

    let handleSubmit = async (e) => {
        setIsLoading(true)
        console.log("dd")
        e.preventDefault();
        let iserror = false;
        if (projectIdTicket.length === 0 || projectIdTicket == "Choose the Project") {
            iserror = true;
        }
        if (milestones.length > 0 && (mileStoneIdTicket.length === 0 || mileStoneIdTicket == "Choose The Milestone")) {
            iserror = true;
        }
        if (ticketComment.length === 0) {
            iserror = true;
        }
        if (!iserror) {
            try {
                // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/createDispute", {
                let res = await fetch(process.env.REACT_APP_API_URL + "/submitTicket", {
                    method: "POST",
                    mode: 'cors',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify({
                        "comment": ticketComment,
                        "mileStoneId": mileStoneIdTicket,
                        "projectId": projectIdTicket,
                        "walletAdress": publicKey.toString()
                    }),
                });
                let resJson = await res.text();
                console.log(resJson)
                setIsLoading(false)
                if (res.status === 200) {
                    iserror = false;
                    (document.getElementById("textAreaCommnt") as HTMLInputElement).value = '';
                    (document.getElementById("projectIdTickt") as HTMLInputElement).value = '';
                    if (milestones.length > 0) {
                        (document.getElementById("mileStoneIdTickt") as HTMLInputElement).value = ''
                        SetMileStoneIdTicket("")
                    };
                    SetProjectIdTicket("");
                    SetTicketComment("");
                    Swal.fire({
                        title: 'Your Ticket is Submitted Successfully. ',
                        text: 'Our team will get back to you shortly',
                        confirmButtonText:
                            'Ok'
                    })
                } else {
                    (document.getElementById("textAreaCommnt") as HTMLInputElement).value = '';
                    (document.getElementById("projectIdTickt") as HTMLInputElement).value = '';
                    if (milestones.length > 0) {
                        (document.getElementById("mileStoneIdTickt") as HTMLInputElement).value = ''
                        SetMileStoneIdTicket("")
                    };
                    SetProjectIdTicket("");
                    SetTicketComment("");
                    console.log("failure")
                }
            } catch (err) {
                (document.getElementById("textAreaCommnt") as HTMLInputElement).value = '';
                (document.getElementById("projectIdTickt") as HTMLInputElement).value = '';
                if (milestones.length > 0) {
                    (document.getElementById("mileStoneIdTickt") as HTMLInputElement).value = ''
                    SetMileStoneIdTicket("")
                };
                SetProjectIdTicket("");
                SetTicketComment("");
                console.log(err);
            }
        } else {
            setIsLoading(false)
            Swal.fire({
                title: 'Some Details Are Empty',
                text: 'Please check and submit again.',
                confirmButtonText:
                    'Ok'
            })
        }

    };

    useEffect(() => {
        if (projectIdTicket && projectIdTicket != "Choose the Project") {
            let selected_contract = totalContract.find((element) => {
                return element.projectId === projectIdTicket;
            })

            setmilestones(selected_contract['mileStones'])
        }

    }, [projectIdTicket]);

    useEffect(() => {
        getAllContract();
    }, []);
    return (<>
        {isLoading && <Loader />}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-11">
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bolder fs-3 mb-1">Support Ticket</span>
                                    <span className="text-muted mt-1 fw-bold fs-7">If you have ‌questions or face any transaction issues while creating a contract, you can create a support ticket by selecting the respective project and milestone. <br/>Our team will get back to you shortly.</span>
                                </h3>

                            </div>
                            <div className="card-body pb-3">
                                {/* <div style={{padding:"20px"}}><input type="text" id="projectIdTickt" className="form-control" onChange={handleProjectIdChange} placeholder="Enter Project Id"/></div> */}
                                {/* <div style={{padding:"20px"}}><input type="text"  id="mileStoneIdTickt" className="form-control" onChange={handleMilestoneChange} placeholder="Enter MileStone Id" /></div> */}
                                <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                        <span className="required">Choose The Project</span>
                                    </label>
                                    <select id="projectIdTickt"
                                        className="form-select form-select-solid"
                                        name="created_for"
                                        onChange={(e) => SetProjectIdTicket(e.target.value)} >
                                        <option>Choose the Project</option>
                                        {totalContract.map(contract =>
                                            <option key={contract.projectId} value={contract.projectId}>{contract.projectTitle}</option>
                                        )}
                                    </select>
                                </div>
                                {milestones.length > 0 &&
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                            <span className="required">Choose The Milestone</span>
                                        </label>
                                        <select id="mileStoneIdTickt"
                                            className="form-select form-select-solid"
                                            name="created_for"
                                            onChange={(e) => SetMileStoneIdTicket(e.target.value)} >
                                            <option>Choose the Milestone</option>
                                            {milestones.map(milestone =>
                                                <option key={milestone.mileStoneId} value={milestone.mileStoneId}>{milestone.mileStoneTitle}</option>
                                            )}
                                        </select>
                                    </div>
                                }


                                <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                        <span className="required">Comments</span>
                                    </label>
                                    <textarea id="textAreaCommnt" className="form-control" aria-label="With textarea" onChange={handleChange}>{ticketComment}</textarea>
                                </div>

                                <div style={{ padding: "0 20px 20px 20px" }}><button onClick={handleSubmit} className="btn btn-primary submit-btn er fs-6 px-8 py-4">Submit</button></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
};