import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import "./transactions.css";
import moment from "moment";

export const Transactions: FC = () => {
    // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
    const [isLoading, setIsLoading] = useState(false);
    const { publicKey } = useWallet();
    const [transactions, settransactions] = useState([])
    const get_transactions = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL+'getTransactions/' + publicKey.toString(), { mode: 'cors' });
            const data = await response.json();
            console.log(data)
            settransactions(data.reverse())
            setIsLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    }




    useEffect(() => {
        setIsLoading(true)
        if (publicKey) {
            get_transactions();
            //   setInterval(init, 1000);
        }
    }, [publicKey]);

    return (<>
        {isLoading && <Loader />}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">


                {/* <div className="d-flex flex-wrap flex-stack">
                    <h3 className="fw-bolder my-2">Transactions
                    </h3>
                </div> */}
                <div className="tab-content pt-8">
                    <div className="card card-flush">
                        <div className="card-body pt-3 table-responsive">

                            <table id="kt_profile_overview_table" className="table table-row-bordered gy-4 align-middle fw-bolder">

                                <thead className="fs-7 text-gray-400 text-uppercase">
                                    <tr>

                                        <th className="min-w-230px">Project Name</th>
                                        <th className="min-w-230px">Milestone Title</th>
                                        <th className="min-w-20px">Invoice to</th>
                                        <th className="min-w-70px">Contract Date</th>
                                        <th className="min-w-10px">Contract Cost</th>
                                        <th className="min-w-10px">Transaction Status</th>
                                        <th className="min-w-110px">Transactions Url</th>

                                    </tr>
                                </thead>

                                {(transactions.length > 0) ? (
                                    <tbody className="fs-6">
                                        {transactions.map((transaction, index) => (
                                            <tr>
                                                <td className="fw-bolder">
                                                    <a href="#" className="text-gray-900 text-hover-primary">{transaction.projectName}</a>
                                                </td>
                                                <td>
                                                    {transaction.milestoneTitle}
                                                </td>
                                                <td>{transaction.invoiceTo}</td>


                                                <td>{transaction.contractDate}</td>

                                                <td>
                                                    {transaction.contractCost} ◎
                                                </td>
                                                <td className="">
                                                    <span className="badge badge-primary fw-bolder me-auto">{transaction.transactionStatus}</span>
                                                </td>
                                                <td className="transaction-hash">
                                                    {transaction['transactionSignatures'] && transaction['transactionSignatures'].map((signature, index) =>

                                                        <div>
                                                            {process.env.REACT_APP_SOLANA_NETWORK == 'devnet' ?
                                                                <div>
                                                                    {signature.signatureType == 'INITIATED' && 'Contract Created'}
                                                                    {signature.signatureType == 'Accepted' && 'Contract Accepted'}
                                                                    {signature.signatureType == 'Completed' && 'Payment Released'}
                                                                    {signature.signatureType == 'ContractWithDrawn' && 'Contract Withdrawn'}
                                                                    -  <a href={"https://solscan.io/tx/" + signature.signatureHash + '?cluster=devnet'} target={"blank"}>View On Solscan</a>
                                                                </div>
                                                                :
                                                                <div>
                                                                      {signature.signatureType == 'INITIATED' && 'Contract Created'}
                                                                    {signature.signatureType == 'Accepted' && 'Contract Accepted'}
                                                                    {signature.signatureType == 'Completed' && 'Payment Released'}

                                                                    - <a href={"https://solscan.io/tx/" + signature.signatureHash} target={"blank"}>View On Solscan</a>
                                                                </div>
                                                            }
                                                            <div className="separator my-2"></div>
                                                        </div>

                                                    )}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : ''}


                            </table>

                        </div>
                    </div>
                </div>





            </div>
        </div>
    </>);
};