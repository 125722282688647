import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { Loader } from "../components/loader";
import { useParams } from "react-router-dom";
import "./project-details.css";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const ProjectDetails: FC = () => {
  let { id } = useParams();
  const [contract, setcontract] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [current_user_type, setcurrent_user_type] = useState("");
  const navigate = useNavigate();
  const [tokenType, setTokenType] = useState();
  const get_project = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getProject/" +
          localStorage.getItem("project_id") +
          "/" +
          localStorage.getItem("p_key"),
        { mode: "cors" }
      );
      const data = await response.json();
      if (data.createdBy == "buyer") {
        if (data.buyerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("buyer");
        } else if (data.sellerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("seller");
        }
      } else {
        if (data.sellerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("buyer");
        } else if (data.buyerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("seller");
        }
      }
      setcontract(data);
      setTokenType(data.mileStones[0].tokenType);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    // if (publicKey) {
    get_project();
    // exit;
    //   setInterval(init, 1000);
    // }
  }, []);
  console.log(id);
  // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  return (
    <>
      {isLoading && <Loader />}
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
        <div className="card mb-5 mb-xl-10">
              <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div className="me-7 mb-4 project-firstname-container">
                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                      <div className="project-firstname">
                        {"projectTitle" in contract
                          ? contract["projectTitle"][0]
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                          <a
                            href="#"
                            className="text-gray-900 text-hover-primary fs-2 me-1 fw-bolder"
                          >
                            {contract["projectTitle"]}
                          </a>
                        </div>

                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                          <a
                            href="#"
                            className="d-flex align-items-center  text-hover-primary mb-2 font-color-3"
                          >
                            {current_user_type == "seller" ? "Buyer" : "Seller"}
                          </a>
                          :
                          <a
                            href="#"
                            className="mx-2 d-flex align-items-center  text-hover-primary mb-2 link-3-color"
                            data-toggle="tooltip"
                            title={
                              current_user_type == "seller"
                                ? contract["buyerAddress"]
                                : contract["sellerAddress"]
                            }
                          >
                            {current_user_type == "seller"
                              ? contract["buyerprofileName"] != "" &&
                                contract["buyerprofileName"] != null
                                ? contract["buyerprofileName"]
                                : contract["buyerAddress"]
                              : contract["sellerprofileName"] != "" &&
                                contract["sellerprofileName"] != null
                              ? contract["sellerprofileName"]
                              : contract["sellerAddress"]}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap flex-stack">
                      <div className="d-flex flex-column flex-grow-1 pe-8">
                        <div className="d-flex flex-wrap">
                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                           
                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["totalAmount"] != null
                                  ? contract["totalAmount"].toFixed(4)
                                  : ""}
                                {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Total Cost</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                            

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["escrowHoldingCost"] != null
                                  ? contract["escrowHoldingCost"].toFixed(4)
                                  : ""}
                                {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Escrow Holding</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                             

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["totalPaid"] != null
                                  ? contract["totalPaid"].toFixed(4)
                                  : ""}
                               {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Total Paid</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                             

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["reaminingCost"] != null
                                  ? contract["reaminingCost"].toFixed(4)
                                  : ""}
                               {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Remaining</div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                <span className="fw-bold fs-6">{contract['progress']}</span>
                                                <span className="fw-bolder fs-6">{contract['progress'] == 'COMPLETED' ? 100 : ''}</span>
                                            </div>
                                            <div className="h-5px mx-3 w-100 bg-light mb-3">
                                                <div className={contract['progress'] == 'COMPLETED' ? "bg-primary rounded h-5px" : "bg-success rounded h-5px"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "30%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>

                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5"
                      to={{
                        pathname: "/project-milestone/" + contract["projectId"],
                      }}
                    >
                      Milestones
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5 active"
                      to={{
                        pathname: "/project-details/" + contract["projectId"],
                      }}
                    >
                      Description and Terms
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5"
                      to={{
                        pathname: "/project-files/" + contract["projectId"],
                      }}
                    >
                      Files
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5"
                      to={{
                        pathname: "/project-feedback/" + contract["projectId"],
                      }}
                    >
                      Feedback
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Description and Terms</h3>
              </div>
            </div>
            <div className="card-body p-9">
              <p className="project-details-text">
              {contract["projectDescription"]}
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
