import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Loader } from "../components/loader";
import { useParams } from "react-router-dom";
import "./project-details.css";
import { Link } from "react-router-dom";
import S3 from "react-aws-s3";
import Swal from "sweetalert2";
import download from "../images/cloud-computing.png";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "./project-files.css";

export const ProjectFiles: FC = () => {
  let { id } = useParams();
  const [contract, setcontract] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [current_user_type, setcurrent_user_type] = useState("");
  const fileInput: any = useRef();
  const [uploadSucess, setUploadSucess] = useState(false);
  const [allAttachments, setAllAttachments] = useState([]);
  const [fileUploadModal, setfileUploadModal] = useState(false);
  const [tokenType, setTokenType] = useState();

  // const [mileStoneID, setMileStoneID] = useState();
  //const mileStoneID: any= useRef();

  const get_project = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getProject/" +
          localStorage.getItem("project_id") +
          "/" +
          localStorage.getItem("p_key"),
        { mode: "cors" }
      );
      const data = await response.json();
      if (data.createdBy == "buyer") {
        if (data.buyerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("buyer");
        } else if (data.sellerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("seller");
        }
      } else {
        if (data.sellerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("buyer");
        } else if (data.buyerAddress == localStorage.getItem("p_key")) {
          setcurrent_user_type("seller");
        }
      }

      setcontract(data);
      setTokenType(data.mileStones[0].tokenType);
      if (data.mileStones && data.mileStones.length > 0) {
        // setMileStoneID(data.mileStones[0].mileStoneId);
        localStorage.setItem("mileStoneKey", data.mileStones[0].mileStoneId);
        get_All_Attachment(data.mileStones[0].mileStoneId);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const get_All_Attachment = async (mileStoneID?) => {
    console.log("attachmentsList");
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getAllAttachments/" +
          localStorage.getItem("project_id") +
          "/" +
          mileStoneID,
        { mode: "cors" }
      );
      const data = await response.json();
      setAllAttachments(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const downloadFile = async (attachment) => {
    try {
      setIsLoading(true);
      await fetch(process.env.REACT_APP_API_URL + "download/" + attachment)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "vaultx";
          a.click();
        });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const saveAttachment = async (newFileName) => {
    let data = {
      attachment: newFileName,
      milestoneid: localStorage.getItem("mileStoneKey"),
      projectId: localStorage.getItem("project_id"),
      walletAddress: localStorage.getItem("p_key"),
      attchmentState: "Project File",
    };
    try {
      setIsLoading(true);
      let res = await fetch(process.env.REACT_APP_API_URL + "saveAttachment", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      console.log(resJson);
      if (res.status === 200) {
        console.log("success");
        setfileUploadModal(false);
        setIsLoading(false);
        Swal.fire({
          text: "Your file has been uploaded.",
          confirmButtonText: "Ok",
        }).then(() => {
          get_All_Attachment(localStorage.getItem("mileStoneKey"));
        });
      } else {
        console.log("failure");
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    // if (publicKey) {
    get_project();

    // exit;
    //   setInterval(init, 1000);
    // }
  }, []);
  console.log(id);
  const handleClick = (event) => {
    event.preventDefault();
    setUploadSucess(false);
    if (fileInput) {
      let file = fileInput!.current.files[0];

      if (file) {
        setIsLoading(true);
        let newFileName = fileInput!.current.files[0].name.replace(/\..+$/, "");
        let projectId = localStorage.getItem("project_id");
        let milestoneId = localStorage.getItem("m_id");
        newFileName =
          projectId.substring(0, projectId.indexOf("-")) +
          "_" +
          milestoneId.substring(0, milestoneId.indexOf("-")) +
          "_" +
          Math.random() +
          file.name;
        //setFileNameFrProjFile(newFileName);
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          console.log(data);
          if (data.status === 204) {
            console.log("success");
            setIsLoading(false);
            setUploadSucess(true);
            fileInput.current.value = "";
            saveAttachment(newFileName);
          } else {
            console.log("fail");
            setIsLoading(false);
            fileInput.current.value = "";
            setUploadSucess(false);
          }
        });
      }
    }
  };

  const handle_close_file_upload_modal = () => setfileUploadModal(false);

  const gettime = (timestamp) => {
    var stillUtc = moment.utc(timestamp, "YYYY-MM-DD HH:mm:ss a").toDate();
    var local = moment(stillUtc, "YYYY-MM-DD HH:mm:ss a")
      .local()
      .format("MMMM Do YYYY, h:mm a");

    return local;
  };

  const openFileuploadModal = () => {
    setfileUploadModal(true);
    setUploadSucess(false);
  };

  // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  return (
    <>
      {isLoading && <Loader />}
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
        <div className="card mb-5 mb-xl-10">
              <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div className="me-7 mb-4 project-firstname-container">
                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                      <div className="project-firstname">
                        {"projectTitle" in contract
                          ? contract["projectTitle"][0]
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                          <a
                            href="#"
                            className="text-gray-900 text-hover-primary fs-2 me-1 fw-bolder"
                          >
                            {contract["projectTitle"]}
                          </a>
                        </div>

                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                          <a
                            href="#"
                            className="d-flex align-items-center  text-hover-primary mb-2 font-color-3"
                          >
                            {current_user_type == "seller" ? "Buyer" : "Seller"}
                          </a>
                          :
                          <a
                            href="#"
                            className="mx-2 d-flex align-items-center  text-hover-primary mb-2 link-3-color"
                            data-toggle="tooltip"
                            title={
                              current_user_type == "seller"
                                ? contract["buyerAddress"]
                                : contract["sellerAddress"]
                            }
                          >
                            {current_user_type == "seller"
                              ? contract["buyerprofileName"] != "" &&
                                contract["buyerprofileName"] != null
                                ? contract["buyerprofileName"]
                                : contract["buyerAddress"]
                              : contract["sellerprofileName"] != "" &&
                                contract["sellerprofileName"] != null
                              ? contract["sellerprofileName"]
                              : contract["sellerAddress"]}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap flex-stack">
                      <div className="d-flex flex-column flex-grow-1 pe-8">
                        <div className="d-flex flex-wrap">
                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                           
                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["totalAmount"] != null
                                  ? contract["totalAmount"].toFixed(4)
                                  : ""}
                                {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Total Cost</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                            

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["escrowHoldingCost"] != null
                                  ? contract["escrowHoldingCost"].toFixed(4)
                                  : ""}
                                {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Escrow Holding</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                             

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["totalPaid"] != null
                                  ? contract["totalPaid"].toFixed(4)
                                  : ""}
                               {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Total Paid</div>
                          </div>

                          <div className="border border-gray-300 rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                             

                              <div
                                className="fs-2 font-3-color"
                                data-kt-countup-prefix={tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":"◎"}
                              >
                                {contract["reaminingCost"] != null
                                  ? contract["reaminingCost"].toFixed(4)
                                  : ""}
                               {tokenType=="Sol"?"◎":tokenType=="Vaultx"?"USDC":tokenType=="Forge"?"FORGE":tokenType=="Yaku"?"YAKU":tokenType=="vBLSH"?"vBLSH":"◎"}
                              </div>
                            </div>

                            <div className="fs-6 subtext-font-color">Remaining</div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                <span className="fw-bold fs-6">{contract['progress']}</span>
                                                <span className="fw-bolder fs-6">{contract['progress'] == 'COMPLETED' ? 100 : ''}</span>
                                            </div>
                                            <div className="h-5px mx-3 w-100 bg-light mb-3">
                                                <div className={contract['progress'] == 'COMPLETED' ? "bg-primary rounded h-5px" : "bg-success rounded h-5px"} role="progressbar" style={{ width: contract['progress'] == 'COMPLETED' ? "100%" : "30%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>

                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5"
                      to={{
                        pathname: "/project-milestone/" + contract["projectId"],
                      }}
                    >
                      Milestones
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5 "
                      to={{
                        pathname: "/project-details/" + contract["projectId"],
                      }}
                    >
                      Description and Terms
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5 active"
                      to={{
                        pathname: "/project-files/" + contract["projectId"],
                      }}
                    >
                      Files
                    </Link>
                  </li>

                  <li className="nav-item mt-2">
                    <Link
                      className="nav-link text-active-primary ms-0 me-10 py-5"
                      to={{
                        pathname: "/project-feedback/" + contract["projectId"],
                      }}
                    >
                      Feedback
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Files</h3>
              </div>
              <div className="card-toolbar">
                <a
                  className="btn btn-md me-3 cancel-btn"
                  onClick={() => openFileuploadModal()}
                  data-bs-toggle="tab"
                >
                  Upload File
                </a>
              </div>
            </div>

            <div className="card-body p-9">
              <div className="tab-content mt-8">
                <div
                  id="kt_project_users_card_pane"
                  className="tab-pane fade active show"
                >
                  <div className="table-responsive">
                    <table className="files-table table align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bolder text-muted">
                          <th className="w-10px">#</th>
                          <th className="min-w-300px">Uploaded By</th>
                          <th className="min-w-125px">Uploaded Time</th>
                          <th className="min-w-125px">Attachment</th>
                          <th className="min-w-125px">Attachment State</th>
                          <th className="min-w-200px text-end rounded-end"></th>
                        </tr>
                      </thead>

                      {allAttachments.length > 0 ? (
                        <tbody>
                          {allAttachments.map((attachment, index) => (
                            <tr key={attachment.projectId}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex justify-content-start flex-column">
                                    {attachment.uploadedBy.substring(0, 7)}...
                                    {attachment.uploadedBy.substring(
                                      35,
                                      attachment.uploadedBy.length
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>{gettime(attachment.uploadedTimeStamp)}</td>
                              <td>
                                {attachment.attachment && (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      downloadFile(attachment.attachment)
                                    }
                                    className="text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6"
                                  >
                                    <span className="menu-icon me-0">
                                      <i className="bi bi-cloud-arrow-down fs-1"></i>
                                    </span>
                                  </a>
                                )}
                              </td>
                              <td>{attachment.attachmentState}</td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          {" "}
                          <tr>
                            <td colSpan={5} className="text-center">
                              No Files Uploaded!
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>

              <Modal
                show={fileUploadModal}
                onHide={handle_close_file_upload_modal}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>File Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form
                    onSubmit={handleClick}
                    id="modal_request_change_form"
                    className="form"
                    action="#"
                  >
                    <div
                      style={{ padding: "10px" }}
                      className="flex-child green mb-5 attach-file"
                    >
                      <div className="image-upload d-flex flex-column fv-row">
                        <label htmlFor="file-input">
                          <span className="fs-6 fw-bold">Attach File</span>
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          ref={fileInput}
                          onClick={() => setUploadSucess(false)}
                          accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*"
                        />
                      </div>
                    </div>

                    {uploadSucess && (
                      <div>
                        <span className="badge badge-light-success ms-2 fs-7">
                          Upload is successful!
                        </span>
                      </div>
                    )}

                    <div className="text-center">
                      <button
                        type="reset"
                        className="btn btn-light me-3"
                        onClick={handle_close_file_upload_modal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleClick}
                        type="submit"
                        className="btn btn-primary"
                      >
                        <span className="indicator-label">Submit</span>
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
